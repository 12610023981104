import { css } from "@emotion/react";
import { theme } from "@xometry/xometry_loft";

const panelBase = css`
  box-sizing: border-box;
  border: 1px solid ${theme.colors.grays[5]};
  display: block;
  margin-bottom: 16px;
`;

const whiteBackground = css`
  background-color: ${theme.colors.white} !important;
`;

const lightPanel = [panelBase, whiteBackground];

export { panelBase, lightPanel };

export default {
  panelBase,
  lightPanel,
};
