/* eslint-disable func-names */
import qs from "qs";
import { Maybe } from "../schema/generated/schema";

export const uppercaseFirst = function(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
};

export const currencyFormat = function(amount: number, currency = "USD_CENTS") {
  const price = (amount / 100)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // just using the currency because its coming from the query,
  // to support Eur currency formatting, we'd honestly need more
  // for example, "." as thousands separator, and "," as
  // decimal separator.
  return `${currency === "USD_CENTS" ? "$" : ""}${price}${
    currency === "EUR_CENTS" ? " &euro;" : ""
  }`;
};

export function getDotNeutralData() {
  const apiUrl = "https://app.dotneutral.com/v1/stats?api_key=";
  const pubKey = "pub.16ehQh88vjE00VvIgy98O0u07yI66Va8";

  return fetch(apiUrl + pubKey, {
    cache: "force-cache",
  }).then(res => res.json());
}

export function punchoutInQueryString() {
  const queryString = window.location.search.split("?").pop() as string;
  const queryParams = qs.parse(queryString);
  const punchout = queryParams.punchout || null;
  return !!punchout;
}

export function punchoutSessionInQueryString() {
  const queryString = window.location.search.split("?").pop() as string;
  const queryParams = qs.parse(queryString);
  const punchout = queryParams.punchout_session_id || "";
  return punchout;
}

export const getLowerCaseEmailDomain = (email: Maybe<string>) => {
  return (email?.split("@")[1] || "").toLowerCase();
};
