import { personalizationAPIKey, personalizationDomain } from "./constants";

const fetchContent = (
  userId: string | null,
  touchpointId: string | null
): Promise<any> => {
  return fetch(
    `${personalizationDomain}/content?user_id=${userId}&touchpoint_id=${touchpointId}`,
    {
      headers: {
        "API-Token": personalizationAPIKey,
      },
    }
  ).then(res => {
    if (res.ok) {
      return res.json();
    }

    return Promise.reject(new Error(`${res.status}: ${res.statusText}`));
  });
};

export { fetchContent };
