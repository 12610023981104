// Build-time variables
const isDev = process.env.NODE_ENV === "development";

// Run-time variables
/* eslint-disable no-underscore-dangle */
const publicUrl = window.__env__.PUBLIC_URL || "";
const domain = window.__env__.REACT_APP_BASE_DOMAIN || "xometry.test";
const hostedDomain =
  window.__env__.REACT_APP_BASE_DOMAIN || "stage.xometry.net";
const homeSubdomain = window.__env__.REACT_APP_HOME_PAGE_SUB || "www";
const quoteSubdomain = window.__env__.REACT_APP_QUOTE_SUB || "get";
const partnerSubdomain = window.__env__.REACT_APP_WORK_SUB || "work";
const authSubdomain = window.__env__.REACT_APP_AUTH_SUB || "auth";
const apiSubdomain = window.__env__.REACT_APP_API_SUB || "api";
const getSubdomain = window.__env__.REACT_APP_API_SUB || "get";
const splitIOApiKey = window.__env__.SPLIT_API_KEY;
const personalizationAPIKey = window.__env__.REACT_APP_PERSONALIZATION_API_KEY;
const prismicAccessToken = window.__env__.REACT_APP_PRISMIC_ACCESS_TOKEN;
const graphqlUrl = window.__env__.REACT_APP_GRAPHQL_URL;
const deployEnv = window.__env__.DEPLOY_ENV;
const gitHash = window.__env__.GIT_HASH;
const enterpriseWorkatoApiKey = window.__env__.ENTERPRISE_WORKATO_API_KEY;
const enterpriseWorkatoApiDomain = window.__env__.ENTERPRISE_WORKATO_API_DOMAIN;
const pfsSubdomain = "us-files";
const SENTRY_BASE_URL = window?.__env__?.SENTRY_BASE_URL;
const SENTRY_DSN = window?.__env__?.SENTRY_DSN;
const SENTRY_ENV = window?.__env__?.SENTRY_ENV;
const FULLSTORY_DEV_MODE = window?.__env__?.FULLSTORY_DEV_MODE === "true";
const FULLSTORY_ORG_ID = window?.__env__?.FULLSTORY_ORG_ID;
const API_ORIGIN = window?.__env__?.API_ORIGIN;
const WWW_ORIGIN = window?.__env__?.WWW_ORIGIN;

const protocol = "https";

const auth = `${protocol}://${authSubdomain}.${domain}`;
const home = `${protocol}://${homeSubdomain}.${domain}`;
const webapp = `${protocol}://${quoteSubdomain}.${domain}`;
const work = `${protocol}://${partnerSubdomain}.${domain}`;
const api = `${protocol}://${apiSubdomain}.${domain}`;
const get = `${protocol}://${getSubdomain}.${domain}`;
const pfs = `${protocol}://${pfsSubdomain}.${domain}`;
const hosted = `${protocol}://${homeSubdomain}.${hostedDomain}`;

const prismicDomain =
  deployEnv === "dev"
    ? "https://xometry-marketing-dev.prismic.io/graphql"
    : "https://xometry-marketing.prismic.io/graphql";

const personalizationDomain =
  deployEnv === "dev"
    ? "https://apiw.xmtr.io/dev/personalization"
    : "https://apiw.xmtr.io/personalization";

const maxFileSize = 100;
const maxFileSizeBytes = maxFileSize * 1024 * 1024;
const KILOGRAMS_PER_POUND = 0.453592;

const origins = {
  auth, // add /v1
  home,
  webapp,
  work,
  api, // add /v2
  hosted,
  pfs,
  get,
  domain,
};

export {
  origins,
  publicUrl,
  maxFileSize,
  maxFileSizeBytes,
  splitIOApiKey,
  personalizationAPIKey,
  personalizationDomain,
  prismicAccessToken,
  prismicDomain,
  graphqlUrl,
  deployEnv,
  gitHash,
  KILOGRAMS_PER_POUND,
  enterpriseWorkatoApiKey,
  enterpriseWorkatoApiDomain,
  SENTRY_BASE_URL,
  SENTRY_DSN,
  SENTRY_ENV,
  FULLSTORY_DEV_MODE,
  FULLSTORY_ORG_ID,
  API_ORIGIN,
  WWW_ORIGIN,
};

export default {
  isDev,
  origins,
  publicUrl,
  splitIOApiKey,
  maxFileSize,
  maxFileSizeBytes,
};
