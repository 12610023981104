/* eslint-disable no-await-in-loop */
import * as emotion from "@emotion/react";
import React from "react";
import * as ReactDOM from "react-dom";

declare global {
  interface Window {
    dynamicImport: (url: string) => Promise<unknown>;
  }
}

const jitScriptLoad = (path: string) => {
  return new Promise((resolve, reject) => {
    const previousAddedScript = document.querySelector(`script[src="${path}"]`);

    if (previousAddedScript) {
      resolve(null);
      return;
    }

    const script = document.createElement("script");
    script.src = path;
    script.onload = resolve;
    script.onerror = reject;
    script.type = path.endsWith(".mjs") ? "module" : "text/javascript";
    document.head.append(script);
  });
};

// Imported external components rely on these scripts
const addImportDependencies = () => {
  // Imported components rely on consuming applications to
  // have some dependencies defined on the window object.
  // See https://github.com/xometry/component-webpack-prototype/blob/main/.webpack/webpackfile.js#L28
  if (typeof window !== "undefined") {
    if (window.React === undefined) {
      window.React = React;
    }

    if (window.ReactDOM === undefined) {
      window.ReactDOM = ReactDOM;
    }

    if (window.emotionReact === undefined) {
      window.emotionReact = emotion;
    }
    if (window.dynamicImport === undefined) {
      window.dynamicImport = url => {
        return import(url);
      };
    }
  }
};

const loadRemoteComponent = (path: string): Promise<unknown> => {
  addImportDependencies();
  return window.dynamicImport(path);
};

const loadRemoteComponentWithRetries = (path: string): Promise<unknown> => {
  addImportDependencies();

  const retryLazyLoad = async () => {
    try {
      return await window.dynamicImport(path);
    } catch (error: any) {
      // retry 5 times with 2 second delay and backoff factor of 2 (2, 4, 8, 16, 32 seconds)
      for (let i = 0; i < 5; i += 1) {
        await new Promise((resolve) => setTimeout(resolve, 1000 * 2 ** i));
        const url = new URL(path);
        // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
        url.searchParams.set("t", `${+new Date()}`);

        try {
          return await window.dynamicImport(url.href);
        } catch (e) {
          console.warn(`Failed to fetch dynamically imported module: Retrying ${i}st/nd attempt`);
        }
      }
      throw error;
    }
  };
  return retryLazyLoad();
};

export { jitScriptLoad, loadRemoteComponent, addImportDependencies, loadRemoteComponentWithRetries };
