/* eslint-disable camelcase */
import { useQuery } from "react-query";
import { origins } from "./constants";

const MAX_VERSION_NUMBER = 99;

export type AllowedFiletype = {
  ext: string;
  display_name?: string;
  display_order?: number;
  numeric_suffixes?: boolean;
};

export type getAllowedPartFileTypesResponse = {
  data: AllowedFiletype[];
};

const fetchAcceptedFileTypes = async (): Promise<getAllowedPartFileTypesResponse> => {
  const res = await fetch(`${origins.api}/v2/parts/allowed-part-file-types`);
  return res.json();
};

export const useAcceptedFileTypes = () => {
  const { data: response } = useQuery<getAllowedPartFileTypesResponse>(
    "acceptedFileTypes",
    fetchAcceptedFileTypes
  );

  const getAcceptedFileTypesList = (): string[] | undefined => {
    return (
      response?.data?.flatMap(ft => {
        // if the filetype has numeric suffixes, add them to the list (e.g. .prt.1, .prt.2, etc...)
        const numericSuffixes = ft.numeric_suffixes
          ? Array.from({ length: MAX_VERSION_NUMBER }, (_, i) => `.${i + 1}`)
          : [];
        return [ft.ext, ...numericSuffixes];
      }) || []
    );
  };

  const getDisplayNames = (separator?: string): string | undefined => {
    return (
      response?.data
        ?.map(ft => ft.display_name)
        ?.filter(ft => !!ft)
        ?.join(separator || ", ") || ""
    );
  };

  return { getAcceptedFileTypesList, getDisplayNames };
};
