import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";
import "regenerator-runtime/runtime";

import ReactDOM from "react-dom";
import React from "react";

import { ApolloProvider } from "@apollo/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { FullStory, init as initFullStory } from "@fullstory/browser";
import * as Sentry from "@sentry/react";
import { fullStoryIntegration } from "@sentry/fullstory";
import App from "./components/App";
import PunchOutContextStore from "./components/PunchOutContext";
import "./index.css";
import client from "./apollo";
import XomErrorBoundary from "./components/XomErrorBoundary/XomErrorBoundary";
import {
  API_ORIGIN,
  FULLSTORY_DEV_MODE,
  FULLSTORY_ORG_ID,
  SENTRY_BASE_URL,
  SENTRY_DSN,
  SENTRY_ENV,
  WWW_ORIGIN,
} from "./utils/constants";

declare global {
  interface Window {
    analytics: any;
    __env__: {
      [key: string]: string;
    };
    FS: {
      identify: (
        arg0: string,
        arg1: {
          displayName: string;
          emailAddress: string;
        }
      ) => any;
    };
    React: any;
    ReactDOM: any;
    emotionReact: any;
    dynamicImport: (url: string) => Promise<unknown>;
    QuantumMetricAPI: any;
    marketplaceFrontendUrl: string;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

if (SENTRY_DSN) {
  const fullStoryOverrideIntegration = (): any => {
    const original = fullStoryIntegration('xometry', {
      client: FullStory,
      baseSentryUrl: SENTRY_BASE_URL,
    });
    return {
      name: 'SentryFullStoryFilter',
      async processEvent(event: any, hint: any, sentryClient: any) {
        if (hint?.originalException && original.processEvent) {
          return original.processEvent(event, hint, sentryClient);
        }
        return event;
      },
    };
  };
  initFullStory(
    {
      devMode: FULLSTORY_DEV_MODE,
      orgId: FULLSTORY_ORG_ID,
    },
    () => {
      Sentry.init({
        dsn: SENTRY_DSN,
        environment: SENTRY_ENV,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: true,
            networkDetailAllowUrls: [API_ORIGIN, WWW_ORIGIN],
          }),
          fullStoryOverrideIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [API_ORIGIN, WWW_ORIGIN],
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
      });
    }
  );
}

// To render other languages replace navigator.languages with fr-FR or pl
ReactDOM.render(
  <XomErrorBoundary>
    <QueryClientProvider client={queryClient}>
      <ApolloProvider client={client}>
        <PunchOutContextStore>
          <App />
        </PunchOutContextStore>
      </ApolloProvider>
    </QueryClientProvider>
  </XomErrorBoundary>,
  document.getElementById("root")
);
