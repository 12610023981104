const track = (cta: string, data = {}) => {
  try {
    window.analytics.track("Customer Home Page CTA Clicked", {
      service: "personalized-homepage-app",
      cta,
      ...data,
    });
  } catch (e) {
    console.error("Segment error: ", e);
  }
};

// Generic track function that accepts any event name and data for event props
const genericTrack = (eventName: string, eventProps: any) => {
  try {
    window.analytics.track(eventName, {
      service: "personalized-homepage-app",
      ...eventProps,
    });
  } catch (e) {
    console.error("Segment error: ", e);
  }
};

const namedPageView = (name: string, category: string, data = {}) => {
  try {
    window.analytics.page(name, {
      service: "personalized-homepage-app",
      category,
      ...data,
    });
  } catch (e) {
    console.error("Segment error: ", e);
  }
};

export { namedPageView, track, genericTrack };

export default { namedPageView, track, genericTrack };
