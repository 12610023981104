export const [mobileBreakPoint, tabletBreakpoint, smDesktopBreakpoint] = [
  "550px",
  "768px",
  "959px",
  "1280px",
];

export const themeOverrides = {
  // this is a large desktop first mindset
  mediaQueries: {
    mobile: `@media (max-width: ${mobileBreakPoint})`,
    tablet: `@media (max-width: ${tabletBreakpoint})`,
    smDesktop: `@media (max-width: ${smDesktopBreakpoint})`,
  },
};

export default {
  themeOverrides,
};
