class ScriptLoader {
  scriptLocation: string;

  scriptHash: string;

  scriptId: string;

  scriptType: string;

  constructor(scriptLocation: string) {
    this.scriptLocation = scriptLocation;
    this.scriptHash = window.btoa(encodeURIComponent(escape(scriptLocation)));
    this.scriptId = `dynamic-script-${this.scriptHash}`;
    this.scriptType = scriptLocation.endsWith(".mjs")
      ? "module"
      : "text/javascript";
  }

  load(): any {
    // attempt to make sure we are not adding the same script multiple times
    const scriptOnPage = document.getElementById(this.scriptId);
    if (!scriptOnPage) {
      const scriptElement = document.createElement("script");
      const loader: any = new Promise((resolve, reject) => {
        scriptElement.src = this.scriptLocation;
        scriptElement.id = this.scriptId;
        scriptElement.type = this.scriptType;
        scriptElement.onload = e => resolve(e);
        scriptElement.onerror = e => reject(e);
        document?.querySelector("head")?.appendChild(scriptElement);
      });
      // using the ability to attach js objects to DOM elements, this allows us to
      // return the above created promise in the case that we call ScriptLoader for
      // the same script URL.
      scriptElement.dataset.jitLoader = loader;
      return loader;
    }
    // a script tag with this src attribute already exists on the document. Return
    // the promise attached to that DOM element.
    return scriptOnPage.dataset.jitLoader;
  }
}

export default ScriptLoader;
